<template>
  <div class="order">
    <el-row :gutter="10" style="width: 100%">
      <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="12">
        <vab-card class="order-card1" shadow="hover">
          <template #header>
            <vab-icon icon="todo-line" />
            办个展
          </template>
          <el-row class="order-card1-content">
            <template v-for="(item, index) in holdExhData" :key="index">
              <el-col :span="item.colCount">
                <p>{{ item.name }}</p>
                <h1>
                  <vab-count
                    :decimals="item.countConfig.decimals"
                    :duration="item.countConfig.duration"
                    :end-val="item.countConfig.endVal"
                    :prefix="item.countConfig.prefix"
                    :separator="item.countConfig.separator"
                    :start-val="item.countConfig.startVal"
                    :suffix="item.countConfig.suffix"
                  />
                </h1>
              </el-col>
            </template>
          </el-row>
        </vab-card>
      </el-col>
      <el-col :lg="12" :md="24" :sm="24" :xl="12" :xs="12">
        <vab-card class="order-card2" shadow="hover">
          <template #header>
            <vab-icon icon="todo-line" />
            办个会
          </template>
          <el-row class="order-card2-content">
            <template v-for="(item, index) in holdMeetData" :key="index">
              <el-col :span="item.colCount">
                <p>{{ item.name }}</p>
                <h1>
                  <vab-count
                    :decimals="item.countConfig.decimals"
                    :duration="item.countConfig.duration"
                    :end-val="item.countConfig.endVal"
                    :prefix="item.countConfig.prefix"
                    :separator="item.countConfig.separator"
                    :start-val="item.countConfig.startVal"
                    :suffix="item.countConfig.suffix"
                  />
                </h1>
              </el-col>
            </template>
          </el-row>
        </vab-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { defineComponent, reactive, toRefs, watch } from 'vue'
  import _ from 'lodash'
  import VabCount from '@/extra/VabCount'

  export default defineComponent({
    components: { VabCount },
    props: {
      exhibitionData: {
        type: Object,
        default: {},
      },
      meetingData: {
        type: Object,
        default: {},
      },
    },
    emits: ['confirm'],
    setup(props, { emit }) {
      const state = reactive({
        //  办个展数据
        holdExhData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '场',
              separator: ',',
              duration: 3000,
            },
            name: '累计办展数量',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '场',
              separator: ',',
              duration: 3000,
            },
            name: '正在进行的展会',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '场',
              separator: ',',
              duration: 3000,
            },
            name: '已结束的展会',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计报名人数',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计嘉宾人数',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计签到人数',
            colCount: 8,
          },
        ],
        //  办个展数据
        holdMeetData: [
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '场',
              separator: ',',
              duration: 3000,
            },
            name: '累计办会数量',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '场',
              separator: ',',
              duration: 3000,
            },
            name: '正在进行的会议',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '场',
              separator: ',',
              duration: 3000,
            },
            name: '已结束的会议',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计报名人数',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计嘉宾人数',
            colCount: 8,
          },
          {
            countConfig: {
              startVal: 0,
              endVal: 0,
              decimals: 0,
              prefix: '',
              suffix: '人',
              separator: ',',
              duration: 3000,
            },
            name: '累计签到人数',
            colCount: 8,
          },
        ],
     
      })
      watch(
        () => [props.exhibitionData, props.meetingData],
        ([exhData, meetData]) => {
          state.holdExhData[0].countConfig.endVal = exhData.holdCount
          state.holdExhData[1].countConfig.endVal = exhData.progressCount
          state.holdExhData[2].countConfig.endVal = exhData.endCount
          state.holdExhData[3].countConfig.endVal = exhData.attendCount
          state.holdExhData[4].countConfig.endVal = exhData.guestCount
          state.holdExhData[5].countConfig.endVal = exhData.signCount
          state.holdMeetData[0].countConfig.endVal = meetData.holdCount
          state.holdMeetData[1].countConfig.endVal = meetData.progressCount
          state.holdMeetData[2].countConfig.endVal = meetData.endCount
          state.holdMeetData[3].countConfig.endVal = meetData.attendCount
          state.holdMeetData[4].countConfig.endVal = meetData.guestCount
          state.holdMeetData[5].countConfig.endVal = meetData.signCount
        }
      )
      return {
        ...toRefs(state),
      }
    },
  })
</script>

<style lang="scss" scoped>
  .order {
    display: flex;
    justify-content: space-between;
    &-card1 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }

    &-card2 {
      width: 100%;
      &-content {
        text-align: center;
      }

      :deep() {
        .el-card {
          &__header,
          &__body {
            color: var(--el-color-white) !important;
            background: linear-gradient(to right, #2cdd7d, #189765);
          }
        }
      }
    }

    margin-bottom: $base-margin;
  }
</style>
